import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Stepper, NotifiedStepper } from '../index';
import { variants, sizes } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Stepper" title="Stepper" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-stepper"
    }}>{`Basic stepper`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            aria-label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper aria-label="Cool label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "ticked-stepper"
    }}>{`Ticked stepper`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            label=\"Cool label\"\n            max={100}\n            min={-100}\n            onChange={(event, value) => console.log(value)}\n            step={10}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" max={100} min={-100} onChange={(event, value) => console.log(value)} step={10} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-stepper"
    }}>{`Labeled stepper`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            label=\"Cool label\"\n            max={12}\n            min={2}\n            onChange={(event, value) => console.log(value)}\n            step={1}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" max={12} min={2} onChange={(event, value) => console.log(value)} step={1} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-stepper-with-placeholder"
    }}>{`Labeled stepper with placeholder`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"stepper\">\n          <Stepper\n            label=\"Cool label\"\n            max={12}\n            min={2}\n            onChange={(event, value) => console.log(value)}\n            placeholder={10}\n            step={1}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" max={12} min={2} onChange={(event, value) => console.log(value)} placeholder={10} step={1} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "labeled-stepper-with-html-value"
    }}>{`Labeled stepper with HTML value`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <StateWrapper initialState={10} key={variant}>\n        {({ state: value, setState: setValue }) => (\n          <Rhythm grouped py={3} style={{ maxWidth: 400 }}>\n            <Stepper\n              alwaysUseFormatting\n              formattedValue={\n                value || value === 0 ? <i>{value}</i> : undefined\n              }\n              label=\"Cool label\"\n              onChange={(event, value) => setValue(value)}\n              value={value}\n              variant={variant}\n            />\n          </Rhythm>\n        )}\n      </StateWrapper>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <StateWrapper initialState={10} key={variant} mdxType="StateWrapper">
          {({
            state: value,
            setState: setValue
          }) => <Rhythm grouped py={3} style={{
            maxWidth: 400
          }} mdxType="Rhythm">
              <Stepper alwaysUseFormatting formattedValue={value || value === 0 ? <i>{value}</i> : undefined} label="Cool label" onChange={(event, value) => setValue(value)} value={value} variant={variant} mdxType="Stepper" />
            </Rhythm>}
        </StateWrapper>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "read-only-stepper"
    }}>{`Read only stepper`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            readOnly\n            label=\"Cool label\"\n            max={12}\n            min={2}\n            onChange={(event, value) => console.log(value)}\n            step={1}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper readOnly label="Cool label" max={12} min={2} onChange={(event, value) => console.log(value)} step={1} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "disabled-stepper"
    }}>{`Disabled stepper`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            disabled\n            label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper disabled label="Cool label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-stepper"
    }}>{`Danger stepper`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"danger\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" onChange={(event, value) => console.log(value)} validity="danger" variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-stepper"
    }}>{`Warning stepper`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"warning\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" onChange={(event, value) => console.log(value)} validity="warning" variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-stepper"
    }}>{`Success stepper`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"success\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper label="Cool label" onChange={(event, value) => console.log(value)} validity="success" variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-stepper"
    }}>{`Contrast stepper`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          contrast\n          unwrapped\n          initialValue={10}\n          property=\"value\"\n          type=\"stepper\"\n        >\n          <Stepper\n            contrast\n            label=\"Cool label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo contrast unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
            <Stepper contrast label="Cool label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Stepper" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={12} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Looper\n        list={variants}\n        render={variant => (\n          <Rhythm py={3}>\n            <FormComponentDemo\n              unwrapped\n              initialValue={10}\n              property=\"value\"\n              type=\"stepper\"\n            >\n              <NotifiedStepper\n                label=\"Cool label\"\n                level={level}\n                notification={`This is an example ${level} notification.`}\n                onChange={(event, value) => console.log(value)}\n                variant={variant}\n              />\n            </FormComponentDemo>\n          </Rhythm>\n        )}\n      />\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
              <FormComponentDemo unwrapped initialValue={10} property="value" type="stepper" mdxType="FormComponentDemo">
                <NotifiedStepper label="Cool label" level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} variant={variant} mdxType="NotifiedStepper" />
              </FormComponentDemo>
            </Rhythm>} mdxType="Looper" />} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped my={3}>\n        <Looper\n          list={variants}\n          render={variant => (\n            <Rhythm key={variant} py={3}>\n              <FormComponentDemo unwrapped property=\"value\" type=\"textbox\">\n                <Stepper\n                  inputWidth=\"auto\"\n                  label={`Size ${size}`}\n                  max={12}\n                  min={2}\n                  onChange={(event, value) => console.log(value)}\n                  placeholder={10}\n                  size={size}\n                  step={1}\n                  variant={variant}\n                />\n              </FormComponentDemo>\n            </Rhythm>\n          )}\n        />\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      FormComponentDemo,
      Stepper,
      NotifiedStepper,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped my={3} mdxType="Rhythm">
          <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
                <FormComponentDemo unwrapped property="value" type="textbox" mdxType="FormComponentDemo">
                  <Stepper inputWidth="auto" label={`Size ${size}`} max={12} min={2} onChange={(event, value) => console.log(value)} placeholder={10} size={size} step={1} variant={variant} mdxType="Stepper" />
                </FormComponentDemo>
              </Rhythm>} mdxType="Looper" />
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Stepper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      